import {Set} from 'immutable';

import {CurrentUser} from 'redux/reducers/user';
import {PartnersById} from 'toolkit/attributes/types';
import {ToolboxGroup, ToolboxItem} from 'toolkit/components/SidebarToolbox';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import {
  isDemandPlanningApplicationEnabled,
  isInventoryPlanningApplicationEnabled,
} from 'toolkit/plans/utils';
import {hasPermission, isAtLeast, isMultiVendor, PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import * as Types from 'types';
import {ascendingBy, descendingBy} from 'utils/arrays';
import {isTruthy} from 'utils/functions';
import {isNovaRecommendationsEnabled} from 'workflows/utils';

const compareByName = ascendingBy((group: ToolboxItem) => group.displayName);

export function getAvailableSettingsGroups(
  currentUser: CurrentUser,
  partners: PartnersById
): readonly ToolboxGroup[] {
  return [
    {
      name: currentUser.vendor.displayName,
      items: [
        {
          name: 'settings/metric-descriptions',
          displayName: 'Metric Descriptions',
          description: 'View descriptions of the metrics used by Alloy.ai',
        },
        {
          name: 'settings/attributes',
          displayName: 'Attributes',
          description: `View, create and edit product, location${
            isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN) &&
            hasPermission(currentUser, Types.PermissionKey.COMPUTE_USE_VIRTUAL_ATTRIBUTES)
              ? ', transaction and virtual'
              : ' and transaction'
          } attributes`,
        },
        ...(isDemandPlanningApplicationEnabled(currentUser)
          ? [
              {
                name: 'settings/plan-settings',
                displayName: 'Planning Settings',
                description: 'Configure Planning application features and new product behaviors',
              },
              {
                name: 'settings/plan-settings/demand-planning',
                displayName: 'Demand Planning',
                description: 'Configure Demand Planning features',
                hideFromSidebar: true,
              },
              isInventoryPlanningApplicationEnabled(currentUser) && {
                name: 'settings/plan-settings/inventory-planning',
                displayName: 'Inventory Planning',
                description: 'Configure Inventory Planning features',
                hideFromSidebar: true,
              },
              isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN) && {
                name: 'settings/plan-settings/forecasts',
                displayName: 'Plan Forecasts',
                description: 'View and config active forecasts for planning',
                hideFromSidebar: true,
              },
              isAtLeast(currentUser.user.role, Types.Role.ADMIN) && {
                name: 'settings/plan-settings/new-products',
                displayName: 'New Product Introductions',
                description: 'Configure adoption characteristics for new products (NPIs)',
                hideFromSidebar: true,
              },
              {
                name: 'settings/plan-settings/plan-demand-sources',
                displayName: 'Plan Demand Source',
                description: 'Configure demand plan basis for each partner',
                hideFromSidebar: true,
              },
              isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN) && {
                name: 'settings/plan-settings/plan-changes',
                displayName: 'Plan Changes',
                description: 'View actions affecting the latest plan',
                hideFromSidebar: true,
              },
              isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN) && {
                name: 'settings/plan-settings/inapplicable-data',
                displayName: 'Inapplicable Plan Data',
                description: 'View data that is no longer applied in plans',
                hideFromSidebar: true,
              },
              {
                name: 'settings/plan-settings/npi-settings',
                displayName: 'Custom NPI Model',
                description: 'Configure a custom adoption model for new product introductions',
                hideFromSidebar: true,
              },
            ]
          : []),
      ]
        .filter(isTruthy)
        .sort(compareByName) as readonly ToolboxItem[],
      requiredRole: Types.Role.USER,
    },
    {
      name: 'Admin Tools',
      className: 'admin-settings',
      items: [
        {
          name: 'settings/analysis-settings',
          displayName: 'Analysis Settings',
          description: 'Edit defaults that affect analysis dashboards',
        },
        {
          name: 'settings/tags',
          displayName: 'Edit Tags',
          description: 'Edit and organize tags for content',
        },
        {
          name: 'settings/users',
          displayName: 'Users',
          description: 'Configure user settings and add new users',
        },
        hasPermission(currentUser, Types.PermissionKey.DESTINATIONS_MANAGE_EMAIL_SUBSCRIPTIONS) && {
          name: 'settings/subscriptions',
          displayName: 'Email Subscriptions',
          description: 'Manage email subscriptions of users',
        },
        hasPermission(
          currentUser,
          Types.PermissionKey.SUBSCRIPTION_MANAGE_SUBSCRIPTION_ONLY_USERS
        ) && {
          name: 'settings/subscription-only-users',
          displayName: 'Subscription Only Users',
          description: 'Configure external email recipients',
        },
        hasPermission(currentUser, Types.PermissionKey.DESTINATIONS_MANAGE_EXPORT_FORMATS) &&
          currentUser.vendor.id !== PUBLIC_VENDOR_ID && {
            name: 'settings/export-formats',
            displayName: 'Export Formats',
            description: 'Configure formats for SFTP exports',
          },
        hasPermission(currentUser, Types.PermissionKey.METRICSNAPSHOT_VIEW_METRIC_SNAPSHOT) && {
          name: 'settings/metric-snapshots',
          displayName: 'Metric Snapshots',
          description: 'Edit metric snapshots',
        },
        isNovaRecommendationsEnabled(currentUser, partners) && {
          name: 'settings/walmart-nova',
          displayName: 'Walmart NOVA',
          description: 'Configure settings for Walmart NOVA Recommendations',
        },
      ]
        .filter(isTruthy)
        .sort(compareByName) as readonly ToolboxItem[],
      requiredRole: Types.Role.ADMIN,
    },
    {
      name: 'Super Admin Tools',
      className: 'super-admin-settings',
      items: [
        {
          name: 'settings/calendars',
          displayName: 'Calendars',
          description: 'View and manage available calendars and aliases for this vendor',
        },
        hasPermission(currentUser, Types.PermissionKey.FORECAST_CONFIGURE_FORECAST) && {
          name: 'settings/forecasts',
          displayName: 'Forecasts',
          description: 'View and configure forecasts',
        },
        {
          name: 'settings/view-links',
          displayName: 'Dashboard Links',
          description: 'Configure which dashboards user can jump to from attributes and metrics',
        },
        {
          name: 'settings/credentials',
          displayName: 'Credentials',
          description: 'Configure partner portal credentials',
        },
        {
          name: 'settings/data-sources',
          displayName: 'Data Sources',
          description: 'View and manage data sources',
        },
        {
          name: 'settings/user-features',
          displayName: 'User-Level Feature Flags',
          description: 'Set feature flags at the user level',
        },
        {
          name: 'settings/product-identifier',
          displayName: 'Product Identifier',
          description: 'Set the vendor product identifier',
        },
        hasPermission(currentUser, Types.PermissionKey.EXPERIMENT_VIEW_EXPERIMENT) && {
          name: 'settings/experiments',
          displayName: 'Experiments',
          description: 'Display and centrally manage experiments',
        },
        hasPermission(currentUser, Types.PermissionKey.GROUP_MANAGE_GROUPS) && {
          name: 'settings/groups',
          displayName: 'Groups',
          description: 'Create and modify groups that are used in virtual attributes',
        },
        {
          name: 'settings/landing-pages',
          displayName: 'Landing Pages',
          description: 'Select which landing pages to show to users',
        },
        {
          name: 'settings/product-lookup',
          displayName: 'Product Lookup',
          description: 'Search products and matches',
        },
        {
          name: 'settings/feeds',
          displayName: 'Feeds',
          description: 'See scraper runs. See scrapers run. Run, scrapers, run!',
        },
        {
          name: 'settings/data-management',
          displayName: 'Data Management',
          description: 'Delete and reload data',
        },
        {
          name: 'settings/twilio-phone-numbers',
          displayName: 'Twilio Phone Numbers',
          description: 'Create and manage Twilio phone numbers used by vendors for data feeds.',
        },
        {
          name: 'settings/security-management',
          displayName: 'Security Management',
          description: 'Manage sessions and API token(s)',
        },
        {
          name: 'settings/compute-requests',
          displayName: 'Compute Requests',
          description: 'View compute requests',
        },
        {
          name: 'settings/widgets',
          displayName: 'Widget Request Monitoring',
          description: 'View compute requests for widgets',
        },
        currentUser.vendor.id !== PUBLIC_VENDOR_ID && {
          name: 'settings/integration-recommendations',
          displayName: 'Vendor Data Integrations',
          description: 'Manage vendor-specific Data Integration settings',
        },
        {
          name: 'settings/edi-channels',
          displayName: 'EDI Channels',
          description: 'Manage EDI channels',
        },
        currentUser.vendor.id !== PUBLIC_VENDOR_ID && {
          name: 'settings/order-schedules',
          displayName: 'Order Schedules',
          description: 'View and manage order schedules',
        },
        hasPermission(currentUser, Types.PermissionKey.TRANSACTION_MANAGE_DATA) && {
          name: 'settings/network-management',
          displayName: 'Network Management',
          description: 'View transaction partner network',
        },
        currentUser.featureFlags.includes(FeatureFlag.SHIPPING_LANE_RULES) &&
          hasPermission(currentUser, Types.PermissionKey.GRAPH_VIEW_SHIPPING_LANE_RULES) && {
            name: 'settings/shipping-lanes',
            displayName: 'Shipping Lanes',
            description: 'View and manage shipping lanes',
          },
        hasPermission(currentUser, Types.PermissionKey.TRANSACTION_MANAGE_IMPLICIT_EVENTS) && {
          name: 'settings/implicit-transaction-events',
          displayName: 'Implicit Transaction Events',
          description: 'View and manage implicit transaction event configurations',
        },
        hasPermission(currentUser, Types.PermissionKey.TRANSACTION_MANAGE_DATA) && {
          name: 'settings/transaction-lookup',
          displayName: 'Transaction Lookup',
          description: 'Look up transaction event match groups by ID or identifiers',
        },
        currentUser.featureFlags.includes(FeatureFlag.EPOCH_SYSTEM) && {
          name: 'settings/epoch',
          displayName: 'Epoch',
          description: 'View and update the current epoch',
        },
        currentUser.featureFlags.includes(FeatureFlag.SALES_DIAGNOSTICS) && {
          name: 'settings/sales-diagnostics',
          displayName: 'Sales Diagnostics',
          description: 'Configure settings for sales diagnostics generation',
        },
        currentUser.featureFlags.includes(FeatureFlag.PRODUCT_MAPPING_WIZARD) && {
          name: 'settings/product-mapping-wizard',
          displayName: 'Product Mapping Wizard',
          description: 'View and update product mapping configurations',
        },
        !isMultiVendor(currentUser) && {
          name: 'settings/data-files',
          displayName: 'Files',
          description: 'Display and manage all files',
        },
      ]
        .filter(isTruthy)
        .sort(compareByName) as readonly ToolboxItem[],
      requiredRole: Types.Role.SUPER_ADMIN,
    },
    {
      name: 'Global',
      className: 'section',
      items: [
        {
          name: 'settings/attribute-descriptions',
          displayName: 'Attribute Descriptions',
          description: 'View and edit descriptions of attributes',
        },
        {
          name: 'settings/credential-groups',
          displayName: 'Credential Groups',
          description: 'Configure new credential groups',
        },
        {
          name: 'settings/user-credentials',
          displayName: 'User Credentials',
          description: 'Manage user credentials across all Alloy.ai vendor instances',
        },
        {
          name: 'settings/jobs',
          displayName: 'Jobs',
          description: 'See in-progress, completed and failed background jobs for last 24h',
        },
        {
          name: 'settings/job-locks',
          displayName: 'Job Locks',
          description: 'Lock jobs so they do not run',
        },
        {
          name: 'settings/feature-flags',
          displayName: 'Feature Flags',
          description: 'Manage feature flags and release features to users and vendors.',
        },
        {
          name: 'settings/vendors',
          displayName: 'Vendors',
          description: 'Create and manage vendors',
        },
        {
          name: 'settings/vendor-registration-requests',
          displayName: 'Vendor Registration Requests',
          description: 'Manage vendor registration requests via Google Cloud Marketplace.',
        },
        {
          name: 'settings/partners',
          displayName: 'Partners',
          description: 'View and create partners',
        },
        {
          name: 'settings/filetypes',
          displayName: 'Filetypes',
          description: 'View, create, and modify filetypes',
        },
        {
          name: 'settings/restricted-filetypes',
          displayName: 'Restricted Filetypes',
          description: 'Manage download restrictions for filetypes containing sensitive data.',
        },
        {
          name: 'settings/forecast-runs',
          displayName: 'Forecast Runs',
          description: 'View forecast runs',
        },
        {
          name: 'settings/scrapers',
          displayName: 'Scrapers',
          description: 'Register or modify scrapers',
        },
        {
          name: 'settings/integrations',
          displayName: 'Integrations',
          description: 'Manage Integrations and Data Sources',
        },
        {
          name: 'settings/usage',
          displayName: 'Usage',
          description: 'How often metrics, attributes, etc. are used',
        },
        {
          name: 'settings/data-rotation',
          displayName: 'Data Rotation',
          description: 'Act on data rotation issues - direct links only',
          hideFromSidebar: true,
        },
        {
          name: 'settings/permissions',
          displayName: 'Permissions',
          description: 'Manage permissions, roles and packages',
          keywords: ['role', 'permission', 'package'],
        },
      ]
        .filter(isTruthy)
        .sort(compareByName) as readonly ToolboxItem[],
      requiredRole: Types.Role.ROOT,
    },
  ].filter(group => isAtLeast(currentUser.user.role, group.requiredRole));
}

export function vendorLocationAttributeComparator(vendor: Types.Vendor) {
  return descendingBy<Types.Attribute>(attr => attr.vendorId === vendor.id).thenAscendingBy(
    attr => attr.name
  );
}

export function getAllVisibleCurrencies(
  settings: Types.VendorAnalysisSettings
): Set<Types.CurrencyCode> {
  return Set([settings.currency, ...settings.additionalVisibleCurrencies]);
}

export interface Settings {
  analysisSettings: Types.VendorAnalysisSettings;
  planSettings?: Types.PlanSettings;
}
