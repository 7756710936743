/**
 * Feature flags are added here as an enum to avoid typos and repetition of strings.
 *
 * If a feature flag is not needed in the frontend, it doesn't need to be added here.
 *
 * Before adding a feature flag here, remember to add the flag in the feature flags CRUD.
 */
export enum FeatureFlag {
  ALLOY_ASSISTANT = 'ALLOY_ASSISTANT',
  SALES_DIAGNOSTICS = 'SALES_DIAGNOSTICS',
  FORECAST_MODULE = 'FORECAST_MODULE',
  INVENTORY_PLANNING = 'INVENTORY_PLANNING',
  LAUNCHPAD_DATA_READINESS = 'LAUNCHPAD_DATA_READINESS',
  PLAN_APPLICATION = 'PLAN_APPLICATION',
  PLAN_METRICS = 'PLAN_METRICS',
  PROMOTION_ANALYSIS = 'PROMOTION_ANALYSIS',
  SHIPPING_LANE_RULES = 'SHIPPING_LANE_RULES',
  STORE_SPECIFIC_ORDERS = 'STORE_SPECIFIC_ORDERS',
  SUPPLY_CHAIN = 'SUPPLY_CHAIN',
  EPOCH_SYSTEM = 'EPOCH_SYSTEM',
  PERCENTAGE_BASE_FILTERS = 'PERCENTAGE_BASE_FILTERS',
  DEPROMO = 'DEPROMO',
  AZURE_DATA_SHARE = 'AZURE_DATA_SHARE',
  DATABRICKS_DATA_SHARE = 'DATABRICKS_DATA_SHARE',
  S3_DESTINATION = 'S3_DESTINATION',
  AZURE_DESTINATION = 'AZURE_DESTINATION',
  PRODUCT_MAPPING_WIZARD = 'PRODUCT_MAPPING_WIZARD',
  SEGMENT_ATTRIBUTES = 'SEGMENT_ATTRIBUTES',
  DATA_STATUS_IN_DASHBOARDS = 'DATA_STATUS_IN_DASHBOARDS',
  SELF_SERVE_INTEGRATION = 'SELF_SERVE_INTEGRATION',
}
