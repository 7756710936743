import equal from 'fast-deep-equal';
import {is as isEqual, List, Set} from 'immutable';
import moment from 'moment-timezone';
import {v4 as uuid} from 'uuid';

import {CurrentUser} from 'redux/reducers/user';
import {Settings} from 'settings/utils';
import {AttributeHierarchies, AttributesById} from 'toolkit/attributes/types';
import {
  createAttributeInstance,
  EXPANDING_ATTRIBUTES,
  getAttribute,
  getAttributeNameForGranularity,
  isBuiltInAttribute,
} from 'toolkit/attributes/utils';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import {getPartnerFilters, mergeAttributeFilters, withoutGraphContext} from 'toolkit/filters/utils';
import {DATE_FORMAT, DATE_HOURS_MINUTES_FORMAT} from 'toolkit/format/constants';
import Format from 'toolkit/format/format';
import {getUnitConvertedMetric} from 'toolkit/metrics/editor/utils';
import {MetricsByName} from 'toolkit/metrics/types';
import {
  createDefaultMetricInstance,
  getEffectiveMetric,
  getFirstAvailableMetric,
  withMetricArguments,
} from 'toolkit/metrics/utils';
import {getUnionOfPeriods} from 'toolkit/time/utils';
import {hasPermission, isAtLeast, PUBLIC_VENDOR_ID, hasAnyPermission} from 'toolkit/users/utils';
import {LoaderAnimation, WidgetDataType, WidgetMetadata} from 'toolkit/views/types';
import {createEmptyView} from 'toolkit/views/utils';
import * as Types from 'types';
import {ascendingBy, first} from 'utils/arrays';
import {falseFunc, isNonNullish, isTruthy, trueFunc} from 'utils/functions';
import {
  chooseFilterAttributes,
  chooseFilterAttributesAndAugmentWithData,
  makeChartComputeRequest,
  makeComputeAndEventRequest,
  makeComputeRequest,
  makeDiagnosticsRequest,
  makeEventsRequest,
  makePlanningRequest,
  makeTableComputeRequest,
} from 'widgets/widget-data';

import calendarIcon from './icons/widget-type-calendar.svg';
import chartIcon from './icons/widget-type-chart.svg';
import doughnutChartIcon from './icons/widget-type-doughnut-chart.svg';
import filterSidebarIcon from './icons/widget-type-filter-sidebar.svg';
import globeIcon from './icons/widget-type-globe.svg';
import headerIcon from './icons/widget-type-header.svg';
import heatMapIcon from './icons/widget-type-heat-map.svg';
import locationMapIcon from './icons/widget-type-location-map.svg';
import pivotTableIcon from './icons/widget-type-pivot-table.svg';
import planningTableIcon from './icons/widget-type-planning-table.svg';
import splitChartIcon from './icons/widget-type-split-chart.svg';
import stackedChartIcon from './icons/widget-type-stacked-chart.svg';
import tabIcon from './icons/widget-type-tab.svg';
import tableIcon from './icons/widget-type-table.svg';
import {ContainerWidgetType, WidgetDisplayMode, WidgetLayoutData} from './types';

export const defaultDevOptions: Types.ComputeDevOptions = {
  useBigQueryCache: true,
  useComputeCache: true,
};

export const MAX_WIDGETS_PER_DASHBOARD = 16;

export function canAddMoreWidgets(
  currentWidgets: List<WidgetLayoutData>,
  currentUser: CurrentUser
) {
  return (
    currentWidgets.size < MAX_WIDGETS_PER_DASHBOARD ||
    isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN)
  );
}

export function widgetViolatesWidgetLimit(
  currentWidgets: List<WidgetLayoutData>,
  currentUser: CurrentUser
) {
  return (
    currentWidgets.size > MAX_WIDGETS_PER_DASHBOARD &&
    !isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN)
  );
}

export const getComputeDevOptions = (widget: Types.Widget) =>
  widget.devOptions || defaultDevOptions;

// new widgets are generated with a negative id, see nextPlaceholderWidgetId
export const isSavedWidget = (widgetId: number | null) => Number(widgetId) > 0;

export function isEditableWidget(widget: Types.Widget, currentUser: CurrentUser): boolean {
  return Widgets[widget.type].isEditable(currentUser);
}

const canEditDiagnosticTable = (currentUser: CurrentUser) =>
  currentUser.featureFlags.has(FeatureFlag.SALES_DIAGNOSTICS);

const defaultWidgetMetadata = {
  isWrappableInContainer: true,
  loaderAnimation: 'OVERLAY' as LoaderAnimation,
  managedMetricArguments: Set(),
  requestType: 'DEFAULT' as Types.ComputeRequestType,
  supportsRowGrouping: true,
  supportsOutdatedData: false,
  supportsCustomMetricNames: true,
} satisfies Partial<WidgetMetadata>;

const dashboardListWidgetMetadata = {
  ...defaultWidgetMetadata,
  dataProvider: null,
  dataType: null,
  icon: {alloyIcon: tableIcon},
  isEditable: trueFunc,
  isLandingPageWidget: true,
  supportsCustomMetricNames: false,
} satisfies Partial<WidgetMetadata>;

const networkStatsWidgetMetadata = {
  ...defaultWidgetMetadata,
  dataProvider: null,
  dataType: null,
  icon: {alloyIcon: headerIcon},
  isEditable: trueFunc,
  isLandingPageWidget: true,
  supportsCustomMetricNames: false,
} satisfies Partial<WidgetMetadata>;

const calendarWidgetMetadata = {
  ...defaultWidgetMetadata,
  dataProvider: makeEventsRequest,
  dataType: WidgetDataType.EVENT,
  isCalendar: true,
  isEditable: trueFunc,
  supportsCalendarEvents: true,
  supportsCustomMetricNames: false,
  requiredPermission: Types.PermissionKey.WIDGET_USE_CALENDAR_WIDGETS,
} satisfies Partial<WidgetMetadata>;

const computeWidgetMetadata = {
  ...defaultWidgetMetadata,
  dataProvider: makeComputeRequest,
  dataType: WidgetDataType.COMPUTE,
  isEditable: trueFunc,
  supportsDebuggingMetrics: true,
  supportsEditingProductMappings: true,
} satisfies Partial<WidgetMetadata>;

const diagnosticWidgetMetadata = {
  ...defaultWidgetMetadata,
  dataProvider: makeDiagnosticsRequest,
  dataType: null,
  isEditable: canEditDiagnosticTable,
  isLandingPageWidget: true,
  supportsCustomMetricNames: false,
} satisfies Partial<WidgetMetadata>;

const tableWidgetMetadata = {
  ...computeWidgetMetadata,
  dataProvider: makeTableComputeRequest,
  supportsCustomMetricColors: true,
} satisfies Partial<WidgetMetadata>;

const planningWidgetMetadata = {
  ...defaultWidgetMetadata,
  dataProvider: makePlanningRequest,
  dataType: WidgetDataType.COMPOSITE,
  isEditable: falseFunc,
  managedMetricArguments: Set.of<Types.MetricArgument>(Types.MetricArgument.period),
  supportsCalendarEvents: true,
  supportsOutdatedData: true,
} satisfies Partial<WidgetMetadata>;

const computeAndEventWidgetMetadata = {
  ...computeWidgetMetadata,
  dataProvider: makeComputeAndEventRequest,
  dataType: WidgetDataType.COMPOSITE,
  managedMetricArguments: Set.of<Types.MetricArgument>(Types.MetricArgument.period),
  supportsCalendarEvents: true,
} satisfies Partial<WidgetMetadata>;

export const Widgets: {readonly [key in Types.WidgetType]: WidgetMetadata} = {
  SINGLE_VALUE: {
    ...tableWidgetMetadata,
    description: 'Show one single value',
    requestType: Types.ComputeRequestType.FLAT,
    icon: {alloyIcon: tableIcon},
    label: 'Single Value',
    shortDisplayName: 'single value',
    isWorkflowPageWidget: true,
    supportsCustomMetricColors: false,
  },
  CALENDAR: {
    ...calendarWidgetMetadata,
    description: 'View and record events that affect your business',
    icon: {alloyIcon: calendarIcon},
    label: 'Calendar',
    loaderAnimation: 'NONE',
    shortDisplayName: 'calendar',
    supportsExporting: true,
  },
  CAROUSEL: {
    ...tableWidgetMetadata,
    description: 'Display Top 2 and Bottom 2',
    icon: {alloyIcon: tableIcon},
    isTable: true,
    label: 'Carousel',
    shortDisplayName: 'carousel',
    isLandingPageWidget: true,
    supportsCustomMetricNames: false,
    supportsCustomMetricColors: false,
  },
  CHART: {
    ...computeAndEventWidgetMetadata,
    dataProvider: makeChartComputeRequest,
    description: 'Compare one metric across time or split by an attribute.',
    icon: {alloyIcon: chartIcon},
    isChart: true,
    isEditable: trueFunc,
    label: 'Chart',
    shortDisplayName: 'chart',
    supportsExporting: true,
    supportsImageExport: true,
  },
  DIAGNOSTICS_TABLE: {
    ...diagnosticWidgetMetadata,
    description: 'Show top insights for recent changes.',
    icon: {alloyIcon: tableIcon},
    label: 'Diagnostics Table',
    shortDisplayName: 'diagnostics',
    supportsExporting: true,
    supportsImageExport: true,
    layoutContainerClassName: 'diagnostics-widget-container',
  },
  DOUGHNUT_CHART: {
    ...computeWidgetMetadata,
    description: 'Compare one metric and split by an attribute.',
    icon: {alloyIcon: doughnutChartIcon},
    isChart: true,
    label: 'Doughnut Chart',
    shortDisplayName: 'doughnut chart',
    supportsExporting: true,
    supportsImageExport: true,
    supportsCustomMetricNames: false,
    requiredPermission: Types.PermissionKey.WIDGET_USE_DOUGHNUT_CHART,
  },
  EVENT_LIST: {
    ...calendarWidgetMetadata,
    description: 'List of events occurring within a specific period of time',
    icon: {alloyIcon: calendarIcon},
    label: 'Events List',
    loaderAnimation: 'BAR',
    shortDisplayName: 'events',
    supportsExporting: true,
  },
  EXPERIMENT_SIDEBAR: {
    ...defaultWidgetMetadata,
    dataProvider: null,
    dataType: null,
    description: 'A sidebar showing details about the selected experiment.',
    icon: {alloyIcon: headerIcon},
    isEditable: falseFunc,
    isSidebar: true,
    isWrappableInContainer: false,
    label: 'Experiment Sidebar',
    loaderAnimation: 'NONE',
    shortDisplayName: 'sidebar',
  },
  EXPERIMENT_CHART: {
    ...computeWidgetMetadata,
    description: 'A chart showing performance of the selected experiment.',
    icon: {alloyIcon: chartIcon},
    isChart: true,
    isEditable: falseFunc,
    label: 'Experiment Chart',
    layoutContainerClassName: 'experiment-chart-widget-container',
    shortDisplayName: 'chart',
  },
  EXPERIMENT_TABLE: {
    ...computeWidgetMetadata,
    description: 'A table showing performance breakdown of test and control groups in experiment.',
    icon: {alloyIcon: tableIcon},
    isTable: true,
    isEditable: falseFunc,
    label: 'Experiment Table',
    layoutContainerClassName: 'experiment-table-widget-container',
    shortDisplayName: 'table',
  },
  FILTER_BAR: {
    ...defaultWidgetMetadata,
    dataProvider: chooseFilterAttributes,
    dataType: WidgetDataType.ATTRIBUTE,
    description: 'Easily filter your dashboard and compare across different filters',
    icon: {alloyIcon: filterSidebarIcon},
    isWrappableInContainer: false,
    isEditable: trueFunc,
    isSidebar: true,
    label: 'Filter Bar',
    isFilterWidget: true,
    shortDisplayName: 'filter bar',
  },
  HEAT_MAP: {
    ...computeWidgetMetadata,
    description: 'Compare geographic values across states or counties.',
    icon: {alloyIcon: heatMapIcon},
    isMap: true,
    label: 'Heat Map',
    shortDisplayName: 'map',
    supportsImageExport: true,
  },
  LOCATION_MAP: {
    ...computeWidgetMetadata,
    description: 'Compare metric values at specific locations.',
    icon: {alloyIcon: locationMapIcon},
    isMap: true,
    label: 'Location Map',
    shortDisplayName: 'map',
    supportsImageExport: true,
  },
  GLOBE: {
    ...computeWidgetMetadata,
    description: 'Compare metric values at specific locations.',
    icon: {alloyIcon: globeIcon},
    isMap: true,
    label: 'Globe',
    shortDisplayName: 'globe',
    supportsImageExport: true,
    requiredPermission: Types.PermissionKey.WIDGET_USE_GLOBE,
  },
  PERFORMANCE_VS_PLAN: {
    ...defaultWidgetMetadata,
    dataProvider: null,
    dataType: null,
    description: 'A variation of a stacked widget for the launchpad.',
    icon: {alloyIcon: tabIcon},
    isContainer: true,
    isEditable: trueFunc,
    isWrappableInContainer: false,
    label: 'Performance Vs Plan Widget',
    shortDisplayName: 'performance',
  },
  PIVOT_TABLE: {
    ...tableWidgetMetadata,
    description: 'Display metrics as columns, and attributes as grouped columns.',
    icon: {alloyIcon: pivotTableIcon},
    isTable: true,
    label: 'Pivot Table',
    shortDisplayName: 'table',
    supportsColumnGrouping: true,
    supportsColumnTransposition: true,
    supportsEmailSubscriptions: true,
    supportsExporting: true,
    supportsIconizedColumns: true,
    requiredPermission: Types.PermissionKey.WIDGET_USE_PIVOT_TABLE,
  },
  PLANNING_CHART: {
    ...planningWidgetMetadata,
    description: 'Visualize plans across time.',
    icon: {alloyIcon: chartIcon},
    isChart: true,
    label: 'Chart',
    managedMetricArguments: Set.of<Types.MetricArgument>(
      Types.MetricArgument.forecastType,
      Types.MetricArgument.granularity,
      Types.MetricArgument.leadTime,
      Types.MetricArgument.period
    ),
    requestType: Types.ComputeRequestType.PLAN,
    shortDisplayName: 'chart',
    supportsExporting: true,
  },
  PLANNING_TABLE: {
    ...planningWidgetMetadata,
    description: 'Design and evaluate sales and replenishment plans.',
    icon: {alloyIcon: planningTableIcon},
    isTable: true,
    label: 'Planning Table',
    loaderAnimation: 'BAR',
    managedMetricArguments: Set.of<Types.MetricArgument>(
      Types.MetricArgument.forecastType,
      Types.MetricArgument.granularity,
      Types.MetricArgument.leadTime,
      Types.MetricArgument.period
    ),
    requestType: Types.ComputeRequestType.PLAN,
    shortDisplayName: 'table',
    supportsEmailSubscriptions: true,
    supportsExporting: true,
    supportsSettingPlanOverrides: true,
  },
  SPLIT_CHART: {
    ...computeAndEventWidgetMetadata,
    description: 'Compare multiple metrics using a stacked bar chart or stacked area chart.',
    icon: {alloyIcon: splitChartIcon},
    isChart: true,
    label: 'Multi-Line/Bar',
    shortDisplayName: 'chart',
    supportsExporting: true,
    supportsImageExport: true,
    supportsCustomMetricNames: false,
  },
  STACKED_CHART: {
    ...computeAndEventWidgetMetadata,
    description: 'Compare multiple metrics using a grouped bar chart or multi-line chart.',
    icon: {alloyIcon: stackedChartIcon},
    isChart: true,
    label: 'Stacked Chart',
    shortDisplayName: 'chart',
    supportsExporting: true,
    supportsImageExport: true,
    supportsCustomMetricNames: false,
  },
  STACKED_WIDGET: {
    ...defaultWidgetMetadata,
    dataProvider: null,
    dataType: null,
    description: 'A stacked container for other widgets.',
    icon: {alloyIcon: tabIcon},
    isContainer: true,
    isEditable: trueFunc,
    isWrappableInContainer: false,
    label: 'Stacked Widget',
    shortDisplayName: 'stacked',
  },
  TABLE: {
    ...tableWidgetMetadata,
    description: 'Display metrics and attributes as columns.',
    icon: {alloyIcon: tableIcon},
    isTable: true,
    label: 'Table',
    requestType: Types.ComputeRequestType.FLAT,
    shortDisplayName: 'table',
    supportsColumnTransposition: true,
    supportsEmailSubscriptions: true,
    supportsExporting: true,
    supportsIconizedColumns: true,
  },
  TAB_WIDGET: {
    ...defaultWidgetMetadata,
    dataProvider: null,
    dataType: null,
    description: 'A tabbed container for other widgets.',
    icon: {alloyIcon: tabIcon},
    isContainer: true,
    isEditable: trueFunc,
    isWrappableInContainer: false,
    label: 'Tab Widget',
    shortDisplayName: 'tabs',
  },
  NETWORK_WIDGET: {
    ...networkStatsWidgetMetadata,
    description: 'A flexbox container for network stat widgets.',
    icon: {alloyIcon: tabIcon},
    isContainer: true,
    isWrappableInContainer: false,
    label: 'Network Widget',
    shortDisplayName: 'network',
    layoutContainerClassName: 'network-widget-container',
  },
  NETWORK_PARTNERS: {
    ...networkStatsWidgetMetadata,
    description: 'The number of partners in vendor network',
    label: 'Partners',
    shortDisplayName: 'partners',
  },
  NETWORK_PRODUCTS: {
    ...networkStatsWidgetMetadata,
    description: 'The number of products in vendor network',
    label: 'Products',
    shortDisplayName: 'products',
  },
  NETWORK_RETAIL_DCS: {
    ...networkStatsWidgetMetadata,
    description: 'The number of retail DCs in vendor network',
    label: 'Retail Distribution Centers',
    shortDisplayName: 'retail DCs',
  },
  NETWORK_RETAIL_STORES: {
    ...networkStatsWidgetMetadata,
    description: 'The number of retail stores in vendor network',
    label: 'Retail Stores',
    shortDisplayName: 'stores',
  },
  NETWORK_WAREHOUSES: {
    ...networkStatsWidgetMetadata,
    description: 'The number of warehouses in vendor network',
    label: 'Warehouses',
    shortDisplayName: 'warehouses',
  },
  TREE_FILTER_BAR: {
    ...defaultWidgetMetadata,
    dataProvider: chooseFilterAttributesAndAugmentWithData,
    dataType: WidgetDataType.ATTRIBUTE,
    description: 'Filter dashboards from a tree view',
    icon: {alloyIcon: filterSidebarIcon},
    isEditable: trueFunc,
    isSidebar: true,
    isWrappableInContainer: false,
    label: 'Tree Filter Bar',
    isFilterWidget: true,
    shortDisplayName: 'filter bar',
  },
  YEARLY_CALENDAR: {
    ...calendarWidgetMetadata,
    description: 'View and record events that affect your business',
    icon: {alloyIcon: calendarIcon},
    label: 'Yearly Calendar',
    loaderAnimation: 'BAR',
    shortDisplayName: 'calendar',
    supportsExporting: true,
  },
  RECENT_FUTURE_EVENTS_LIST: {
    ...calendarWidgetMetadata,
    description: 'View and record events that affect your business',
    icon: {alloyIcon: calendarIcon},
    isLandingPageWidget: true,
    label: 'Recent Promotions',
    loaderAnimation: 'BAR',
    shortDisplayName: 'calendar',
    supportsExporting: false,
  },
  PLAN_FILTER_BAR: {
    ...defaultWidgetMetadata,
    dataProvider: chooseFilterAttributesAndAugmentWithData,
    dataType: WidgetDataType.ATTRIBUTE,
    description: 'Filter planning page from a tree view',
    icon: {alloyIcon: filterSidebarIcon},
    isEditable: falseFunc,
    isSidebar: true,
    isWrappableInContainer: false,
    label: 'Plan Filter Bar',
    isFilterWidget: true,
    shortDisplayName: 'filter bar',
  },
  FAVORITES_LIST: {
    ...dashboardListWidgetMetadata,
    description: 'The favorite items of the user.',
    label: 'Favorites List',
    shortDisplayName: 'favorites',
  },
  RECENTLY_VIEWED_LIST: {
    ...dashboardListWidgetMetadata,
    description: 'The recently viewed items of the user.',
    label: 'Recently Viewed List',
    shortDisplayName: 'recents',
  },
  ADDITIONAL_TEMPLATES_LIST: {
    ...dashboardListWidgetMetadata,
    description: 'Additional Alloy dashboard templates.',
    label: 'Additional Templates',
    shortDisplayName: 'templates',
  },
};

const placeholderWidgetIdHolder: {id: number} = {
  id: -Math.pow(2, 31) + 1, // safe for Java
};

function nextPlaceholderWidgetId() {
  return ++placeholderWidgetIdHolder.id;
}

export function getDefaultWidgetProperties(): Types.Widget {
  return {
    childCount: null,
    columnGroupings: [],
    customName: null,
    options: null,
    devOptions: null,
    filters: [],
    postComputeFilters: [],
    filterAttributesToIgnore: null,
    filterFulfillmentMethods: false,
    hasSummaryHeader: false,
    headerInsight: null,
    helpText: '',
    id: nextPlaceholderWidgetId(),
    ignoresViewFilters: false,
    ignoreGraphContextInFilters: false,
    insightPillMetrics: null,
    isTransposed: false,
    layoutParams: null,
    metricFilters: [],
    metrics: [],
    metricsSplitIndex: null,
    rowGroupings: [],
    rowLimit: null,
    type: Types.WidgetType.TABLE,
    isGrouped: false,
    sortOrder: null,
    viewLinkIds: null,
  };
}

export function createDefaultWidget(
  allGroupings: AttributesById,
  availableMetrics: MetricsByName,
  attributeHierarchies: AttributeHierarchies,
  period: Types.DatePeriod,
  currentUser: CurrentUser,
  type: Types.WidgetType,
  currency: Types.CurrencyCode,
  isGrouped: boolean,
  removeDoubleCountingPartners?: boolean,
  prevConfig?: Types.Widget
) {
  const newWidget = createDefaultWidgetForType(
    allGroupings,
    availableMetrics,
    attributeHierarchies,
    period,
    currentUser.settings,
    true,
    type,
    currency,
    removeDoubleCountingPartners ?? false,
    isGrouped,
    prevConfig
  );
  return prevConfig ? {...newWidget, layoutParams: prevConfig.layoutParams} : newWidget;
}

function createDefaultWidgetForType(
  allGroupings: AttributesById,
  availableMetrics: MetricsByName,
  attributeHierarchies: AttributeHierarchies,
  period: Types.DatePeriod,
  settings: Settings,
  filterFulfillmentMethods: boolean,
  type: Types.WidgetType,
  currency: Types.CurrencyCode,
  removeDoubleCountingPartners: boolean,
  isGrouped: boolean,
  prevConfig?: Types.Widget
): Types.Widget {
  if (
    type === Types.WidgetType.TAB_WIDGET ||
    type === Types.WidgetType.NETWORK_WIDGET ||
    type === Types.WidgetType.STACKED_WIDGET
  ) {
    return createDefaultContainerWidget(type);
  }
  const previousConfig = prevConfig || getDefaultWidgetProperties();
  return {
    ...createDefaultWidgetForLeafType(
      allGroupings,
      availableMetrics,
      attributeHierarchies,
      period,
      settings,
      type,
      previousConfig,
      currency,
      isGrouped,
      removeDoubleCountingPartners
    ),
    id: prevConfig?.id || nextPlaceholderWidgetId(),
    customName: previousConfig.customName,
    devOptions: previousConfig.devOptions,
    filters: previousConfig.filters,
    metricFilters: previousConfig.metricFilters,
    filterFulfillmentMethods,
  };
}

function createDefaultWidgetForLeafType(
  allGroupings: AttributesById,
  availableMetrics: MetricsByName,
  attributeHierarchies: AttributeHierarchies,
  period: Types.DatePeriod,
  settings: Settings,
  type: Types.WidgetType,
  previousConfig: Types.Widget,
  currency: Types.CurrencyCode,
  isGrouped: boolean,
  removeDoubleCountingPartners: boolean
): Types.Widget {
  switch (type) {
    case Types.WidgetType.CALENDAR:
    case Types.WidgetType.YEARLY_CALENDAR:
      return createDefaultCalendarWidget(type);
    case Types.WidgetType.EVENT_LIST:
    case Types.WidgetType.RECENT_FUTURE_EVENTS_LIST:
      return createDefaultEventListWidget(availableMetrics, settings, type, currency);
    case Types.WidgetType.FILTER_BAR:
      return createDefaultFilterBarWidget(allGroupings, removeDoubleCountingPartners);
    case Types.WidgetType.TREE_FILTER_BAR:
      return createDefaultTreeFilterBarWidget(allGroupings, removeDoubleCountingPartners);
    case Types.WidgetType.CHART:
    case Types.WidgetType.STACKED_CHART:
    case Types.WidgetType.SPLIT_CHART:
    case Types.WidgetType.DOUGHNUT_CHART:
    case Types.WidgetType.EXPERIMENT_CHART:
    case Types.WidgetType.PLANNING_CHART:
      return createDefaultChartWidget(
        allGroupings,
        availableMetrics,
        period,
        settings,
        type,
        previousConfig,
        currency
      );
    case Types.WidgetType.EXPERIMENT_SIDEBAR:
      return createDefaultExperimentSidebarWidget();
    case Types.WidgetType.HEAT_MAP:
    case Types.WidgetType.LOCATION_MAP:
    case Types.WidgetType.GLOBE:
      return createDefaultMapWidget(
        allGroupings,
        availableMetrics,
        period,
        settings,
        type,
        previousConfig,
        currency
      );
    case Types.WidgetType.EXPERIMENT_TABLE:
    case Types.WidgetType.TABLE:
    case Types.WidgetType.PIVOT_TABLE:
      return createDefaultTableWidget(
        allGroupings,
        availableMetrics,
        attributeHierarchies,
        settings,
        period,
        type,
        previousConfig,
        currency,
        isGrouped
      );
    case Types.WidgetType.PLANNING_TABLE:
      return createDefaultPlanningTableWidget();
    case Types.WidgetType.TAB_WIDGET:
    case Types.WidgetType.STACKED_WIDGET:
    case Types.WidgetType.NETWORK_WIDGET:
    case Types.WidgetType.PERFORMANCE_VS_PLAN:
      throw new Error(
        'a container widget was unexpectedly passed to createDefaultWidgetForLeafType'
      );
    case Types.WidgetType.PLAN_FILTER_BAR:
      return createDefaultPlanFilterBarWidget(allGroupings);
    case Types.WidgetType.CAROUSEL:
      return createDefaultCarouselWidget(
        allGroupings,
        availableMetrics,
        attributeHierarchies,
        settings,
        period,
        previousConfig,
        currency
      );
    case Types.WidgetType.SINGLE_VALUE:
      return createDefaultSingleValueWidget(
        availableMetrics,
        settings,
        period,
        previousConfig,
        currency
      );
    case Types.WidgetType.FAVORITES_LIST:
    case Types.WidgetType.RECENTLY_VIEWED_LIST:
    case Types.WidgetType.ADDITIONAL_TEMPLATES_LIST:
    case Types.WidgetType.NETWORK_PARTNERS:
    case Types.WidgetType.NETWORK_PRODUCTS:
    case Types.WidgetType.NETWORK_RETAIL_DCS:
    case Types.WidgetType.NETWORK_RETAIL_STORES:
    case Types.WidgetType.NETWORK_WAREHOUSES:
    case Types.WidgetType.DIAGNOSTICS_TABLE:
      return {...getDefaultWidgetProperties(), type};
  }
}

export function getDefaultMetricsForWidget(
  availableMetrics: MetricsByName,
  settings: Settings,
  period: Types.DatePeriod,
  currency: Types.CurrencyCode
): readonly Types.MetricInstanceConfig[] {
  return availableMetrics.isEmpty()
    ? []
    : [
        createDefaultMetricInstance(
          getFirstAvailableMetric(availableMetrics, [
            'sales_units_net',
            'sales_dollars_net',
            'on_hand_units',
          ]),
          period,
          settings,
          availableMetrics,
          currency
        ),
      ]
        .filter(metricInstance => !!metricInstance.metric)
        .map(metricInstance => ({metricInstance}));
}

function getDefaultMapWidgetGroupingNames(type: Types.WidgetType) {
  switch (type) {
    case Types.WidgetType.LOCATION_MAP:
    case Types.WidgetType.GLOBE:
      return ['Location'];
    case Types.WidgetType.HEAT_MAP:
    default:
      return ['Country', 'State'];
  }
}

function getAvailableDefaultMetrics(
  metricConfigs: readonly Types.MetricInstanceConfig[],
  availableMetrics: MetricsByName,
  settings: Settings,
  period: Types.DatePeriod,
  currency: Types.CurrencyCode
): readonly Types.MetricInstanceConfig[] {
  const filteredMetrics = metricConfigs.filter(
    ({metricInstance}) => !!availableMetrics.get(metricInstance.metric.name, null)
  );
  return filteredMetrics.length > 0
    ? filteredMetrics
    : getDefaultMetricsForWidget(availableMetrics, settings, period, currency);
}

function createDefaultMapWidget(
  allGroupings: AttributesById,
  availableMetrics: MetricsByName,
  period: Types.DatePeriod,
  settings: Settings,
  type: Types.WidgetType,
  prevConfig: Types.Widget,
  currency: Types.CurrencyCode
): Types.Widget {
  const rowGroupings = getDefaultMapWidgetGroupingNames(type).flatMap(name =>
    getGroupingsWhichExist(allGroupings, [name])
  );
  const metrics = getAvailableDefaultMetrics(
    prevConfig.metrics,
    availableMetrics,
    settings,
    period,
    currency
  );

  return {
    ...getDefaultWidgetProperties(),
    columnGroupings: [],
    metrics,
    metricsSplitIndex: null,
    rowGroupings,
    type,
  };
}

function createDefaultChartWidget(
  allGroupings: AttributesById,
  availableMetrics: MetricsByName,
  period: Types.DatePeriod,
  settings: Settings,
  type: Types.WidgetType,
  prevConfig: Types.Widget,
  currency: Types.CurrencyCode
): Types.Widget {
  const isSplit = Set.of(
    Types.WidgetType.STACKED_CHART,
    Types.WidgetType.SPLIT_CHART,
    Types.WidgetType.DOUGHNUT_CHART
  ).contains(type);
  const rowGrouping =
    (!EXPANDING_ATTRIBUTES.includes(prevConfig.rowGroupings[0]?.attribute.name) &&
      prevConfig.rowGroupings[0]) ||
    getGroupingIfExists(
      allGroupings,
      getAttributeNameForGranularity(
        settings.analysisSettings.granularity || Types.CalendarUnit.WEEKS
      )
    );
  const columnGrouping = prevConfig.columnGroupings[0] || getGrouping(allGroupings, 'Partner');

  const metricList = getAvailableDefaultMetrics(
    prevConfig.metrics,
    availableMetrics,
    settings,
    settings.analysisSettings.dashboardPeriod,
    currency
  );
  const metrics = (isSplit ? [metricList[0]] : metricList).map(({seriesType, ...rest}) => ({
    ...rest,
    seriesType:
      type === Types.WidgetType.DOUGHNUT_CHART ? null : seriesType || Types.WidgetSeriesType.BAR,
  }));

  const defaultSplitIndex =
    prevConfig.metricsSplitIndex === null ? metrics.length : prevConfig.metricsSplitIndex;
  const metricsSplitIndex = isSplit ? 1 : defaultSplitIndex;

  if (!rowGrouping) {
    throw new Error('Cannot create a chart widget without a row grouping.');
  }

  return {
    ...getDefaultWidgetProperties(),
    columnGroupings: isSplit ? [columnGrouping] : [],
    metrics: metrics.map(({metricInstance, ...rest}) => ({
      metricInstance: withMetricArguments(metricInstance, {period}),
      ...rest,
    })),
    metricsSplitIndex,
    rowGroupings: type === Types.WidgetType.DOUGHNUT_CHART ? [] : [rowGrouping],
    type,
  };
}

function createDefaultCalendarWidget(type: Types.WidgetType): Types.Widget {
  return {...getDefaultWidgetProperties(), type};
}

function createDefaultEventListWidget(
  availableMetrics: MetricsByName,
  settings: Settings,
  type: Types.WidgetType,
  currency: Types.CurrencyCode
): Types.Widget {
  // we don't care about what metric we use, we just need one to carry the date
  const metric = getFirstAvailableMetric(availableMetrics, [
    'forecast_sales_units_net',
    'sales_units_net',
  ]);
  if (!metric) {
    return getDefaultWidgetProperties();
  }
  const period: Types.ComplexDatePeriod = {
    startPeriod: {
      amount: 52,
      unit: Types.CalendarUnit.WEEKS,
      type: 'lastn',
    },
    endPeriod: {
      amount: 13,
      unit: Types.CalendarUnit.WEEKS,
      type: 'nextn',
    },
    type: 'complex',
  };

  const metricInstance = createDefaultMetricInstance(
    metric,
    period,
    settings,
    availableMetrics,
    currency
  );

  return {
    ...getDefaultWidgetProperties(),
    metrics: [{metricInstance}],
    type,
  };
}

function createDefaultFilterBarWidget(
  allGroupings: AttributesById,
  removeDoubleCountingPartners: boolean
) {
  return {
    ...getDefaultWidgetProperties(),
    removeDoubleCountingPartners,
    metrics: [],
    rowGroupings: [getGrouping(allGroupings, 'Partner')],
    type: Types.WidgetType.FILTER_BAR,
  };
}

function createDefaultPlanningTableWidget() {
  return {
    ...getDefaultWidgetProperties(),
    isTransposed: true,
    type: Types.WidgetType.PLANNING_TABLE,
  };
}

const fallbackHierarchyAttributeNames: {[key in Types.AttributeType]: readonly string[]} = {
  LOCATION: ['Partner', 'Census Region', 'Census Division', 'State'],
  PRODUCT: [], // can't provide a reliable set of defaults here, so we won't try
  TRANSACTION_EVENT: ['Purchase Order Number', 'Delivery Date'],
  VIRTUAL: [],
  DATE: [],
  SEGMENT: [],
};

function getFallbackGroupings(attributeType: Types.AttributeType, allGroupings: AttributesById) {
  return getGroupingsWhichExist(allGroupings, fallbackHierarchyAttributeNames[attributeType]);
}

function getGroupingsFromHierarchies(
  attributeType: Types.AttributeHierarchyType,
  defaultAttributeHierarchies: AttributeHierarchies
): readonly Types.AttributeInstance[] | null {
  const attributes = defaultAttributeHierarchies.get(attributeType);
  return attributes && attributes.length > 0
    ? attributes.map(attribute => createAttributeInstance(attribute))
    : null;
}

function getBestGuessHierarchy(
  allGroupings: AttributesById,
  defaultAttributeHierarchies: AttributeHierarchies
) {
  // try product groupings first, then location groupings. if neither exists, the only safe
  // option is location groupings.
  return (
    getGroupingsFromHierarchies(
      Types.AttributeHierarchyType.PRODUCT,
      defaultAttributeHierarchies
    ) ||
    getGroupingsFromHierarchies(
      Types.AttributeHierarchyType.LOCATION,
      defaultAttributeHierarchies
    ) ||
    getFallbackGroupings(Types.AttributeType.LOCATION, allGroupings)
  );
}

function createDefaultTableWidget(
  allGroupings: AttributesById,
  availableMetrics: MetricsByName,
  defaultAttributeHierarchies: AttributeHierarchies,
  settings: Settings,
  period: Types.DatePeriod,
  type: Types.WidgetType,
  prevConfig: Types.Widget,
  currency: Types.CurrencyCode,
  isGrouped: boolean
): Types.Widget {
  const defaultRowGroupings = getBestGuessHierarchy(allGroupings, defaultAttributeHierarchies);

  const prevRowGroupings = prevConfig.rowGroupings;
  const rowGroupings = prevRowGroupings.length > 0 ? prevRowGroupings : defaultRowGroupings;

  const metrics = getAvailableDefaultMetrics(
    prevConfig.metrics,
    availableMetrics,
    settings,
    period,
    currency
  );

  return {
    ...getDefaultWidgetProperties(),
    columnGroupings: type === Types.WidgetType.PIVOT_TABLE ? prevConfig.columnGroupings : [],
    metrics,
    rowGroupings,
    type,
    isGrouped,
  };
}

function createDefaultTreeFilterBarWidget(
  allGroupings: AttributesById,
  removeDoubleCountingPartners: boolean
) {
  return {
    ...getDefaultWidgetProperties(),
    removeDoubleCountingPartners,
    rowGroupings: [getGrouping(allGroupings, 'Partner')],
    type: Types.WidgetType.TREE_FILTER_BAR,
  };
}

function createDefaultPlanFilterBarWidget(allGroupings: AttributesById) {
  return {
    ...getDefaultWidgetProperties(),
    rowGroupings: [getGrouping(allGroupings, 'Partner')],
    type: Types.WidgetType.PLAN_FILTER_BAR,
  };
}

function createDefaultExperimentSidebarWidget(): Types.Widget {
  return {
    ...getDefaultWidgetProperties(),
    type: Types.WidgetType.EXPERIMENT_SIDEBAR,
  };
}

export function createDefaultContainerWidget(type: ContainerWidgetType): Types.Widget {
  return {
    ...getDefaultWidgetProperties(),
    childCount: 1,
    id: nextPlaceholderWidgetId(),
    type,
  };
}

function createDefaultCarouselWidget(
  allGroupings: AttributesById,
  availableMetrics: MetricsByName,
  defaultAttributeHierarchies: AttributeHierarchies,
  settings: Settings,
  period: Types.DatePeriod,
  prevConfig: Types.Widget,
  currency: Types.CurrencyCode
): Types.Widget {
  const defaultRowGroupings = getBestGuessHierarchy(allGroupings, defaultAttributeHierarchies);

  const groupingFilter = (grouping: Types.AttributeInstance) =>
    grouping.attribute.type === Types.AttributeType.PRODUCT;

  const prevRowGroupings = prevConfig.rowGroupings.filter(groupingFilter);
  const firstRowGrouping =
    prevRowGroupings.length > 0 ? first(prevRowGroupings) : first(defaultRowGroupings);

  const metrics = getAvailableDefaultMetrics(
    prevConfig.metrics,
    availableMetrics,
    settings,
    period,
    currency
  );

  return {
    ...getDefaultWidgetProperties(),
    metrics: metrics.length > 1 ? [first(metrics)] : metrics,
    rowGroupings: [firstRowGrouping],
    type: Types.WidgetType.CAROUSEL,
  };
}

function createDefaultSingleValueWidget(
  availableMetrics: MetricsByName,
  settings: Settings,
  period: Types.DatePeriod,
  prevConfig: Types.Widget,
  currency: Types.CurrencyCode
): Types.Widget {
  const metrics = getAvailableDefaultMetrics(
    prevConfig.metrics,
    availableMetrics,
    settings,
    period,
    currency
  );

  return {
    ...getDefaultWidgetProperties(),
    metricFilters: prevConfig.metricFilters,
    filters: prevConfig.filters,
    metrics: [first(metrics)],
    type: Types.WidgetType.SINGLE_VALUE,
  };
}

export function createComputeRequest(
  calendar: Types.RetailCalendarEnum,
  config: Types.Widget,
  evaluationDate: moment.Moment = moment(),
  requestId: string = uuid(),
  computeEvaluationDateTime: moment.Moment | null = null,
  removeDoubleCounting = false
): Types.ComputeRequest {
  return {
    calendar,
    removeDoubleCounting,
    rowLimit: config.rowLimit,
    columnGroupings: config.columnGroupings,
    options: config.options,
    devOptions: config.devOptions,
    evaluationDate: moment(evaluationDate).format(DATE_FORMAT),
    computeEvaluationDateTime:
      computeEvaluationDateTime === null
        ? null
        : moment(computeEvaluationDateTime).format(DATE_HOURS_MINUTES_FORMAT),
    filters: config.filters,
    postComputeFilters: config.postComputeFilters,
    metricFilterGroupings: getMetricFilterGroupings(config),
    // While editing a widget, there can be empty metric filter groups. These will not be saved, so filter them out
    // here to produce consistent results.
    metricFilters: config.metricFilters.filter(filters => filters.length > 0),
    metrics: [
      ...getWidgetMetricInstances(config),
      ...(config.insightPillMetrics?.map(({metricInstance}) => metricInstance) ?? []),
    ],
    requestId,
    rowGroupings: config.rowGroupings,
    type: getWidgetRequestType(config),
  };
}

export function createWidget(
  computeRequest: Types.ComputeRequest,
  customName: string | null = null,
  type: Types.WidgetType | null = null
) {
  const bothRowAndColumnGroupingsPresent =
    computeRequest.rowGroupings.length && computeRequest.columnGroupings.length;
  const widgetType =
    type ??
    (bothRowAndColumnGroupingsPresent ? Types.WidgetType.PIVOT_TABLE : Types.WidgetType.TABLE);
  const isGrouped = computeRequest.type === Types.ComputeRequestType.DEFAULT;
  const isChart = Widgets[widgetType].isChart;
  return {
    childCount: null,
    columnGroupings: computeRequest.columnGroupings,
    customName,
    options: computeRequest.options,
    devOptions: computeRequest.devOptions,
    filters: computeRequest.filters.filter(
      filter => !isBuiltInAttribute(filter.attributeInstance.attribute)
    ),
    postComputeFilters: computeRequest.postComputeFilters,
    filterFulfillmentMethods: !!computeRequest.options?.filterFulfillmentMethods,
    hasSummaryHeader: false,
    id: null,
    ignoresViewFilters: false,
    ignoreGraphContextInFilters: false,
    isTransposed: false,
    layoutParams: null,
    metricFilters: computeRequest.metricFilters,
    metrics: computeRequest.metrics.map(metricInstance => ({
      metricInstance,
      ...(isChart ? {seriesType: Types.WidgetSeriesType.LINE} : {}),
    })),
    metricsSplitIndex: isChart ? computeRequest.metrics.length : null,
    rowGroupings: bothRowAndColumnGroupingsPresent
      ? computeRequest.rowGroupings
      : [...computeRequest.rowGroupings, ...computeRequest.columnGroupings],
    type: widgetType,
    sortOrder: null,
    helpText: '',
    viewLinkIds: null,
    rowLimit: null,
    filterAttributesToIgnore: null,
    headerInsight: null,
    insightPillMetrics: null,
    isGrouped,
  };
}

export function createView(
  computeRequest: Types.ComputeRequest,
  currentUser: CurrentUser
): Types.View {
  return {
    ...createEmptyView(currentUser.user, currentUser.settings.analysisSettings),
    calendar: computeRequest.calendar,
    filters: computeRequest.filters.filter(filter =>
      isBuiltInAttribute(filter.attributeInstance.attribute)
    ),
    layoutType: Types.LayoutType.VERTICAL,
    name: computeRequest.requestId,
    widgets: [createWidget(computeRequest)],
  };
}

function hasWalmartPartnerData(filters: readonly Types.AttributeFilter[]) {
  return getPartnerFilters(filters).every(filter => {
    const attributeFilterValues = filter.values.map(value => value.displayValue);
    return (
      (filter.inclusive && attributeFilterValues.includes('Walmart')) ||
      (!filter.inclusive && !attributeFilterValues.includes('Walmart'))
    );
  });
}

export function canApplyFulfillmentMethodsFilter(
  currentUser: CurrentUser,
  vendorHasFulfillmentData: boolean,
  filters: readonly Types.AttributeFilter[]
) {
  return (
    isFulfillmentMethodsFilterSupported(currentUser, vendorHasFulfillmentData, filters) &&
    hasPermission(currentUser, Types.PermissionKey.COMPUTE_REMOVE_DOUBLE_COUNTING)
  );
}

export function canViewFulfillmentMethodsFilter(
  currentUser: CurrentUser,
  vendorHasFulfillmentData: boolean,
  filters: readonly Types.AttributeFilter[],
  widget: Types.Widget
) {
  if (!isFulfillmentMethodsFilterSupported(currentUser, vendorHasFulfillmentData, filters)) {
    return false;
  }
  const hasDefaultFulfillmentMethodFilterSetting =
    widget.filterFulfillmentMethods ===
    currentUser.settings.analysisSettings.removePartnerDoubleCounting;

  return (
    hasPermission(currentUser, Types.PermissionKey.COMPUTE_REMOVE_DOUBLE_COUNTING) ||
    !hasDefaultFulfillmentMethodFilterSetting
  );
}

export function isFulfillmentMethodsFilterSupported(
  currentUser: CurrentUser,
  vendorHasFulfillmentData: boolean,
  filters: readonly Types.AttributeFilter[]
) {
  // Allow configuring this filter in ALLOY_PUBLIC so that it can be set on the launchpad and templates
  return (
    currentUser.vendor.id === PUBLIC_VENDOR_ID ||
    (vendorHasFulfillmentData && hasWalmartPartnerData(filters))
  );
}

export function getWidgetRequestType(widgetConfig: Types.Widget): Types.ComputeRequestType {
  const widgetMetaData = Widgets[widgetConfig.type];
  if (widgetConfig.isGrouped && widgetMetaData.isTable) {
    return Types.ComputeRequestType.DEFAULT;
  }
  return widgetMetaData.requestType;
}

// Widgets that have (or had) metrics as an implementation detail, but for which we shouldn't use the metric as a title
const NON_METRIC_TITLE_WIDGETS = Set.of<Types.WidgetType>(
  Types.WidgetType.CALENDAR,
  Types.WidgetType.YEARLY_CALENDAR,
  Types.WidgetType.EVENT_LIST,
  Types.WidgetType.RECENT_FUTURE_EVENTS_LIST,
  Types.WidgetType.FILTER_BAR
);

export function getWidgetTitle(widgetConfig: Types.Widget, showDetailedTitle = false): string {
  const firstMetricInstance: Types.MetricInstance | undefined =
    widgetConfig.metrics[0]?.metricInstance;
  const hasFirstMetric = firstMetricInstance && !NON_METRIC_TITLE_WIDGETS.has(widgetConfig.type);
  if (widgetConfig.customName) {
    const period =
      hasFirstMetric && showDetailedTitle ? ` (${Format.metricPeriod(firstMetricInstance)})` : '';
    return `${widgetConfig.customName}${period}`;
  } else if (hasFirstMetric) {
    return showDetailedTitle
      ? Format.metricDetailed(firstMetricInstance)
      : Format.metric(firstMetricInstance);
  }

  return Widgets[widgetConfig.type].label;
}

export function getGrouping(
  allGroupings: AttributesById,
  name: string,
  graphContext: Types.GraphContext | null = null
): Types.AttributeInstance {
  const result = getGroupingIfExists(allGroupings, name, graphContext);
  if (!result) {
    throw new Error(`No attribute found for '${name}'`);
  }
  return result;
}

export function getGroupingIfExists(
  allGroupings: AttributesById,
  name: string,
  graphContext: Types.GraphContext | null = null
): Types.AttributeInstance | null {
  const attribute = getAttribute(allGroupings, name);
  return attribute ? createAttributeInstance(attribute, graphContext) : null;
}

export function getGroupings(
  allGroupings: AttributesById,
  names: readonly string[]
): readonly Types.AttributeInstance[] {
  return names.map(name => getGrouping(allGroupings, name));
}

export function getGroupingsWhichExist(
  allGroupings: AttributesById,
  names: readonly string[]
): readonly Types.AttributeInstance[] {
  return names.map(name => getGroupingIfExists(allGroupings, name)).filter(isTruthy);
}

export function getMetricFilterGroupings(config: Types.Widget) {
  return config.rowGroupings
    .concat(config.columnGroupings)
    .filter(grouping => grouping.attribute.type !== Types.AttributeType.DATE);
}

export function getEffectiveWidgetFilters(
  viewFilters: readonly Types.AttributeFilter[],
  config: Types.Widget
) {
  const filterAttributeIdsToIgnore = (config.filterAttributesToIgnore ?? []).map(attr => attr.id);
  const adjustedViewFilters = viewFilters
    .filter(
      viewFilter =>
        !config.ignoresViewFilters &&
        !filterAttributeIdsToIgnore.includes(viewFilter.attributeInstance.attribute.id)
    )
    .map(viewFilter =>
      config.ignoreGraphContextInFilters ? withoutGraphContext(viewFilter) : viewFilter
    );
  return mergeAttributeFilters(adjustedViewFilters.concat(config.filters));
}

export function getUnionOfWidgetPeriods(config: Types.Widget): Types.DatePeriod | null {
  return getUnionOfPeriods(
    config.metrics.map(({metricInstance}) => metricInstance.arguments.period!)
  );
}

export function areMetricFiltersSupported(widgetConfig: Types.Widget) {
  return !findMetricFilterUnsupportedGroupings(widgetConfig);
}

export function findMetricFilterUnsupportedGroupings(widgetConfig: Types.Widget) {
  return (
    List.of(widgetConfig.rowGroupings, widgetConfig.columnGroupings)
      .map(groupings => {
        const dateGrouping = groupings.find(
          grouping => grouping.attribute.type === Types.AttributeType.DATE
        );
        const nonDateGrouping = groupings.find(
          grouping => grouping.attribute.type !== Types.AttributeType.DATE
        );
        return dateGrouping && nonDateGrouping && {dateGrouping, nonDateGrouping};
      })
      .filter(isTruthy)
      .first() || null
  );
}

export function usesEventLift(widget: Types.Widget) {
  return widget.metrics.some(({metricInstance}) =>
    metricInstance.metric.features.includes(Types.MetricFeature.USES_EVENT_LIFT)
  );
}

function stripAppearancePropsFromMetrics(
  metrics: readonly Types.MetricInstanceConfig[]
): readonly Types.MetricInstanceConfig[] {
  return metrics.map(metricConfig => ({
    ...metricConfig,
    customColor: null,
    customDescription: null,
    customName: null,
    seriesType: null,
    useReverseColors: null,
    isIconized: null,
  }));
}

export function stripNonDataProps(widget: Types.Widget): Types.Widget {
  return {
    ...widget,
    metrics: stripAppearancePropsFromMetrics(widget.metrics),
    customName: null,
    id: null,
    isTransposed: null,
    layoutParams: null,
    hasSummaryHeader: false,
    // This strictly for type safety purposes. We're not actually clearing values but using this
    // function to aid with comparisons.
    type: Types.WidgetType.TABLE,
    helpText: '',
    viewLinkIds: null,
  };
}

export function isWidgetRefreshRequired(prevWidget: Types.Widget, newWidget: Types.Widget) {
  return (
    !isEqual(stripNonDataProps(prevWidget), stripNonDataProps(newWidget)) ||
    Widgets[prevWidget.type].requestType !== Widgets[newWidget.type].requestType
  );
}

export function supportsMetricHighlight(widgetType: Types.WidgetType) {
  return Widgets[widgetType].isMap || Widgets[widgetType].isTable;
}

function getComparableMetric(metric: Types.Metric): Types.Metric {
  return {
    ...metric,
    categories: [],
    destinationMetric: null,
    features: [],
    forecastedMetrics: [],
    optionalArguments: [],
    originMetric: null,
    requiredArguments: [],
    transactionStatuses: [],
  };
}

function getComparableMetricInstance(metricInstance: Types.MetricInstance): Types.MetricInstance {
  return {
    metric: getComparableMetric(metricInstance.metric),
    arguments: {
      ...metricInstance.arguments,
      childMetrics: metricInstance.arguments.childMetrics?.map(getComparableMetric) ?? null,
      comparisonMetrics:
        metricInstance.arguments.comparisonMetrics?.map(getComparableMetricInstance) ?? null,
    },
  };
}

export function mapWidgetMetricInstances(
  widget: Types.Widget,
  f: (metricInstance: Types.MetricInstance) => Types.MetricInstance
): Types.Widget {
  return {
    ...widget,
    metrics: widget.metrics.map(({metricInstance, ...rest}) => ({
      metricInstance: f(metricInstance),
      ...rest,
    })),
    insightPillMetrics:
      widget.insightPillMetrics?.map(({metricInstance, ...rest}) => ({
        metricInstance: f(metricInstance),
        ...rest,
      })) ?? null,
    metricFilters: widget.metricFilters.map(filters =>
      filters.map(({metric, ...rest}) => ({metric: f(metric), ...rest}))
    ),
  };
}

export function getComparableWidget(widget: Types.Widget): Types.Widget {
  const mappedWidget = mapWidgetMetricInstances(widget, getComparableMetricInstance);
  return {
    ...mappedWidget,
    filters: [...mappedWidget.filters].sort(
      ascendingBy(attrFilter => attrFilter.attributeInstance.attribute.name)
    ),
    metricFilters: mappedWidget.metricFilters.map(metricFilters =>
      [...metricFilters].sort(
        ascendingBy(
          metricFilter =>
            `${metricFilter.metric.metric.name}${metricFilter.predicate}${metricFilter.value}`
        )
      )
    ),
    options: null,
  };
}

export function isCompactDisplayMode(displayMode: WidgetDisplayMode) {
  return displayMode !== 'normal' && displayMode !== 'padded';
}

export function replacePeriodsForWidget(
  widget: Types.Widget,
  previousViewPeriod: Types.DatePeriod,
  newPeriod: Types.DatePeriod
): Types.Widget {
  return mapWidgetMetricInstances(widget, metricInstance =>
    updateMetricForDefaultPeriod(metricInstance, previousViewPeriod, newPeriod)
  );
}

export function updateMetricForDefaultPeriod(
  metric: Types.MetricInstance,
  previousPeriod: Types.DatePeriod,
  newPeriod: Types.DatePeriod
) {
  const comparisonMetrics = updateDefaultPeriodInComparisonMetrics(
    metric.arguments,
    previousPeriod,
    newPeriod
  );
  return {
    ...metric,
    arguments: {
      ...metric.arguments,
      comparisonMetrics,
      period: areEquivalentPeriods(previousPeriod, metric.arguments.period!)
        ? newPeriod
        : metric.arguments.period,
    },
  };
}

function updateDefaultPeriodInComparisonMetrics(
  metricArguments: Types.MetricArguments,
  previousPeriod: Types.DatePeriod,
  newPeriod: Types.DatePeriod
) {
  if (!metricArguments.comparisonMetrics) {
    return null;
  }
  const baseMetricInstance = metricArguments.comparisonMetrics[0];
  const compareToMetricInstance = metricArguments.comparisonMetrics[1];
  const basePeriodSameAsPrevious = areEquivalentPeriods(
    baseMetricInstance.arguments.period!,
    previousPeriod
  );
  const comparisonPeriodSameAsPrevious = areEquivalentPeriods(
    compareToMetricInstance.arguments.period!,
    previousPeriod
  );
  return [
    {
      ...baseMetricInstance,
      arguments: {
        ...baseMetricInstance.arguments,
        period: basePeriodSameAsPrevious ? newPeriod : baseMetricInstance.arguments.period,
      },
    },
    {
      ...compareToMetricInstance,
      arguments: {
        ...compareToMetricInstance.arguments,
        period:
          basePeriodSameAsPrevious && comparisonPeriodSameAsPrevious
            ? newPeriod
            : compareToMetricInstance.arguments.period,
      },
    },
  ];
}

function areEquivalentLastAndPrevious(a: Types.DatePeriod, b: Types.DatePeriod) {
  return (
    a.type === 'lastn' &&
    b.type === 'previous' &&
    a.unit === b.unit &&
    a.amount === 1 &&
    b.amount === 1
  );
}

export function areEquivalentPeriods(a: Types.DatePeriod, b: Types.DatePeriod) {
  return equal(a, b) || areEquivalentLastAndPrevious(a, b) || areEquivalentLastAndPrevious(b, a);
}

export function replaceUnitConversionAttributesForWidget(
  settings: Settings,
  availableMetrics: MetricsByName,
  widget: Types.Widget,
  previousAttribute: Types.Attribute | null,
  newAttribute: Types.Attribute | null,
  currency: Types.CurrencyCode
): Types.Widget {
  function updateMetricForUnitConversionAttribute(metric: Types.MetricInstance) {
    if (previousAttribute?.id !== metric.arguments.unitConversionAttribute?.id) {
      return metric;
    }

    return getUnitConvertedMetric(settings, availableMetrics, metric, newAttribute, currency);
  }

  return mapWidgetMetricInstances(widget, updateMetricForUnitConversionAttribute);
}

export function replaceCurrencyArgumentForWidget(
  widget: Types.Widget,
  newCurrency: Types.CurrencyCode
): Types.Widget {
  const updateMetricCurrency: (metric: Types.MetricInstance) => Types.MetricInstance = metric => ({
    ...metric,
    arguments: {
      ...metric.arguments,
      currency:
        metric.arguments.currency === Types.CurrencyCode.AS_REPORTED
          ? metric.arguments.currency
          : metric.arguments.currency && newCurrency,
      comparisonMetrics: metric.arguments.comparisonMetrics?.map(updateMetricCurrency) || null,
    },
  });

  return mapWidgetMetricInstances(widget, updateMetricCurrency);
}

export function shouldWidgetShowSeeAllButton(
  currentUser: CurrentUser,
  showSeeAllButton: boolean | undefined,
  widget: Types.Widget,
  hasCustomSeeAllButtonLink?: boolean
) {
  return (
    showSeeAllButton &&
    ((widget.type === Types.WidgetType.RECENT_FUTURE_EVENTS_LIST &&
      hasAnyPermission(
        currentUser,
        Types.PermissionKey.WORKFLOW_USE_PROMOTION_ANALYSIS,
        Types.PermissionKey.EVENTS_MANAGE_EVENTS
      )) ||
      (isNonNullish(widget.viewLinkIds) &&
        hasPermission(currentUser, Types.PermissionKey.DASHBOARD_VIEW_DASHBOARD)) ||
      hasCustomSeeAllButtonLink)
  );
}

export function shouldWidgetAllowRowClickMode(widget: Types.Widget) {
  return !!widget.viewLinkIds;
}

export function getWidgetPrimaryMetric(config: Types.Widget): Types.Metric | null {
  const primaryMetricInstanceConfig = first(config.metrics);
  if (primaryMetricInstanceConfig) {
    return getEffectiveMetric(primaryMetricInstanceConfig.metricInstance);
  }
  return null;
}

export function getWidgetMetricInstances(widget: Types.Widget): readonly Types.MetricInstance[] {
  return widget.metrics.map(({metricInstance}) => metricInstance);
}

export const NETWORK_CONTAINER_WIDGETS: readonly Types.WidgetType[] = [
  Types.WidgetType.NETWORK_PARTNERS,
  Types.WidgetType.NETWORK_PRODUCTS,
  Types.WidgetType.NETWORK_RETAIL_DCS,
  Types.WidgetType.NETWORK_RETAIL_STORES,
  Types.WidgetType.NETWORK_WAREHOUSES,
  Types.WidgetType.SINGLE_VALUE,
];
