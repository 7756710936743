import './VendorSelect.scss';

import classNames from 'classnames';
import {List} from 'immutable';
import React, {useMemo, useState} from 'react';

import * as Types from 'types';
import {toCamelCaseShorthand} from 'utils/search';
import {vendorComparator} from 'vendors/utils';

import {AttachmentProps} from './Dropdown';
import Select, {SelectProps} from './Select';

interface VendorOption {
  readonly isActive: boolean;
  readonly label: string;
  readonly value: number;
  readonly shorthand: string;
}

const VendorSelect: React.FC<Props> = props => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  return (
    <ControlledVendorSelect
      {...props}
      isDropdownOpen={isDropdownOpen}
      setDropdownOpen={setDropdownOpen}
    />
  );
};

export const ControlledVendorSelect: React.FC<ControlledVendorSelectProps> = ({
  allowedVendors,
  children,
  className,
  displayPlaceholderNameInSearch,
  onSelect,
  selectedVendor,
  clearable = false,
  ...rest
}) => {
  const options: readonly VendorOption[] = useMemo(
    () =>
      allowedVendors
        .sort(vendorComparator)
        .map(vendor => ({
          isActive: vendor.active,
          label: vendor.displayName,
          value: vendor.id!,
          shorthand: toCamelCaseShorthand(vendor.displayName),
        }))
        .toArray(),
    [allowedVendors]
  );

  const handleChange = (selection: VendorOption | null, {event}: {event: React.UIEvent}) => {
    const isMetaKeyDown = 'metaKey' in event ? !!event.metaKey : false;
    if (selection && selection.value !== null && selection.value !== undefined) {
      onSelect(allowedVendors.find(vendor => vendor.id === selection.value) || null, isMetaKeyDown);
    } else if (clearable) {
      onSelect(null, isMetaKeyDown);
    }
  };

  const selectedOption = options.find(option => option.value === selectedVendor);

  return (
    <Select<VendorOption>
      {...rest}
      children={children}
      className={classNames('VendorSelect', className)}
      clearable={clearable}
      getOptionDisplayName={option => option.label}
      getRowClassName={option => (option.isActive ? 'vendor-select-active-vendor' : undefined)}
      options={options}
      optionTypeDisplayName={displayPlaceholderNameInSearch ? 'Vendor' : undefined}
      placeholder="Select vendor…"
      selectedOption={selectedOption}
      tabIndex={0}
      onChange={handleChange}
    />
  );
};

VendorSelect.displayName = 'VendorSelect';

type Props = Omit<
  SelectProps<number>,
  | 'options'
  | 'multi'
  | 'onChange'
  | 'getOptionDescriptionTooltip'
  | 'getOptionDisplayName'
  | 'getOptionSubtitle'
  | 'getRowClassName'
  | 'getSearchableOption'
  | 'optionLabelRenderer'
  | 'optionRenderer'
  | 'menuRenderer'
  | 'valueRenderer'
  | 'optionIsDisabled'
> & {
  allowedVendors: List<Types.Vendor>;
  children?: React.ReactNode;
  onSelect: (vendor: Types.Vendor | null, openInNewWindow: boolean) => void;
  selectedVendor: number | undefined;
  dropdownAttachmentProps?: AttachmentProps;
  displayPlaceholderNameInSearch?: boolean;
  showCheckOnSelection?: boolean;
  clearable?: boolean;
};

type ControlledVendorSelectProps = Props & {
  isDropdownOpen: boolean;
  setDropdownOpen: (isOpen: boolean) => void;
};

export default VendorSelect;
